/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowDown from 'modules/common/Svg/ArrowDown';
import Badge from 'modules/common/Svg/Badge';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {compose, withHandlers, withState} from 'recompose';
import {achCountColor, fixedHeight, marginIcon} from './styles.scss';

const ProfileAchievements = ({
  achievements,
  isCurrentUser,
  showMore,
  visibleBoxes,
}) => {
  const achievementsArray = Object.values(achievements.data).filter(
    (achievement) => typeof achievement === 'object',
  );
  return (
    <div className="bg-white pv3 ph3">
      <div className="flex">
        <h2 className="f3 fw6 mt4 mr3" data-cy="achievements-title">
          <FormattedMessage id="achievements_points" />
        </h2>
        <p className={`f4 fw6 ${achCountColor} mt4 mr2`}>
          ({achievementsArray.length})
        </p>
      </div>
      {achievementsArray.length > 0 ? (
        <div className="cf">
          {achievementsArray.map(
            ({achievement: {title, date_obtained, topic}}, i) =>
              i < visibleBoxes && (
                <div
                  key={i}
                  className={`${fixedHeight} fr w-third-ns w-100 w-50-m w-50-mid pv4 bb b--light-gray mb3 ph2`}
                >
                  <div className="flex">
                    <div>
                      <Badge width="50px" height="65px" />
                    </div>
                    <div className="ph2">
                      <div>
                        <h4>{title}</h4>
                      </div>
                      <p className="silver">{topic.name}</p>
                      <p className="f6 mt3 silver">
                        <FormattedDate
                          value={new Date(date_obtained)}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ),
          )}
          {visibleBoxes < achievementsArray.length && (
            <div className="tc">
              <a
                className="pointer pa2 dib f6 f5-ns fw6 tc no-underline ba bw1 br2 b--light-gray gray"
                onClick={showMore}
              >
                <div className="flex justify-between ph2">
                  <p className="ph1">
                    <FormattedMessage id="more" />
                  </p>
                  <div className={`${marginIcon} ph1`}>
                    <ArrowDown color="gray" />
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="pa4 f4 tc">
            <FormattedMessage
              id={isCurrentUser ? 'no_badges_self' : 'no_badges'}
            />
          </p>
        </div>
      )}
    </div>
  );
};

ProfileAchievements.propTypes = {
  achievements: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  showMore: PropTypes.func.isRequired,
  visibleBoxes: PropTypes.number,
};

const enhance = compose(
  withState('visibleBoxes', 'setVisibleBoxes', 6),
  withHandlers({
    showMore: ({setVisibleBoxes}) => () => {
      setVisibleBoxes((visibleBoxes) => visibleBoxes + 6);
    },
  }),
);

export default enhance(ProfileAchievements);
