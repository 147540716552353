import {Link} from 'gatsby';
import EarnPoint from 'modules/common/Svg/EarnPoint';
import Location from 'modules/common/Svg/Location';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import calendar from 'theme/assets/images/calendar.svg';
import settings from 'theme/assets/images/settings.svg';
import facebook from 'theme/assets/images/socialmedia/facebook.svg';
import github from 'theme/assets/images/socialmedia/github.svg';
import linkedin from 'theme/assets/images/socialmedia/linkedin.svg';
import twitter from 'theme/assets/images/socialmedia/twitter.svg';
import {avatarImg, avatarSize, blueFont} from './styles.scss';

const ProfileInfos = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {isCurrentUser, profile, userScore} = props;
  const socialLinksArray = Object.values(profile.social_links).filter(
    (social_link) => typeof social_link === 'object',
  );
  return (
    <div className="bg-near-white w-30-ns pv3">
      <div className="w-100">
        <div className="tc">
          {profile.avatar_url && (
            <div className={`relative ${avatarImg}`}>
              <img
                className={`br-100 ${avatarSize}`}
                alt={profile.first_name}
                src={profile.avatar_url}
              />
            </div>
          )}
          <p className="fw6 f5 pv2" data-cy="first-and-last-name">
            {profile.first_name} {profile.last_name}
          </p>
          {profile.slug && (
            <p className="silver fw6 ltr pv2" data-cy="user-slug">
              @{profile.slug}
            </p>
          )}
          {isCurrentUser && (
            <div className="pv4">
              <Link
                className="tc fw6 white pv2 bw1 dib no-underline br2 bg-green-light hover-bg-green"
                to="/account/edit"
              >
                <div className="flex justify-between ph2">
                  <div className="ph1">
                    <img
                      width="15px"
                      height="15px"
                      src={settings}
                      alt={formatMessage({id: 'settings'})}
                    />
                  </div>
                  <p className="ph1">
                    <FormattedMessage id="edit_profile" />
                  </p>
                </div>
              </Link>
            </div>
          )}
          <div className="bb b--moon-gray" />
        </div>
        {profile.about && (
          <div>
            <div className="mr2 tc tr-ns mt2 pa4">
              <h3 className="fw6 f5 pb2">
                <FormattedMessage id="about_me" />
              </h3>
              <p className="pv2 silver">{profile.about}</p>
            </div>
            <div className="bb b--moon-gray" />
          </div>
        )}
        {userScore && (
          <div className="mr2 tc tr-ns mt2 pa4">
            <h3 className="fw6 f5 pb2">
              <FormattedMessage id="sum_points" />
            </h3>
            <div className="flex ph2">
              <EarnPoint width="33px" height="33px" color="#69b1f4" />
              <p className={`ph2 mt2 ${blueFont}`}>
                {userScore}{' '}
                <FormattedMessage
                  id={userScore > 10 ? 'points_max' : 'points_min'}
                />
              </p>
            </div>
          </div>
        )}
        <div className="bb b--moon-gray" />
        <div className="mr2 tc tr-ns mt2 pa4">
          {profile.nationality && (
            <div className="flex ph2">
              <Location color="#16c988" />
              <p className="ph2 mt1 black-80">{profile.nationality}</p>
            </div>
          )}
          {profile.date_joined && (
            <div className="flex pa2">
              <img src={calendar} alt={formatMessage({id: 'calendar'})} />
              <p className="ph2 mt1 black-80">
                <FormattedMessage id="member_since" />{' '}
                <FormattedDate
                  value={new Date(profile.date_joined)}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />{' '}
              </p>
            </div>
          )}
        </div>
        <div className="bb b--moon-gray" />
        <div className="flex justify-start-ns justify-center mr2-ns mt2 pv2 ph4-ns">
          {socialLinksArray.map(({provider, link}, i) => (
            <div key={i}>
              {provider === 'GITHUB' && (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={link}
                  className="w2 link pointer dim dib pl2"
                  title={formatMessage({id: 'label_github'})}
                >
                  <img src={github} alt="Github" />
                </a>
              )}
              {provider === 'FACEBOOK' && (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={link}
                  className="w2 link pointer dim dib pl2"
                  title={formatMessage({id: 'label_facebook'})}
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              )}
              {provider === 'TWITTER' && (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={link}
                  className="w2 link pointer dim dib pl2"
                  title={formatMessage({id: 'twitter'})}
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              )}
              {provider === 'LINKEDIN' && (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={link}
                  className="w2 link pointer dim dib pl2"
                  title={formatMessage({id: 'label_linkedin'})}
                >
                  <img
                    className="br-100 bg-light-silver"
                    src={linkedin}
                    alt="LinkedIn"
                  />
                </a>
              )}
            </div>
          ))}
        </div>
        <div className="mr2 tc tr-ns mt2 pv2 ph4-ns">
          <a
            href={profile.url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="silver link dim"
          >
            {profile.url}
          </a>
        </div>
      </div>
    </div>
  );
};

ProfileInfos.propTypes = {
  isCurrentUser: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  userScore: PropTypes.number.isRequired,
};

export default ProfileInfos;
