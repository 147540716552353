import React from 'react';
import {Match, Redirect} from '@reach/router';
import ProfileContainer from 'modules/profiles/ProfileContainer';

const UserPages = () => {
  return (
    <Match path="/user/:userSlug">
      {({match}) =>
        match ? (
          <ProfileContainer userSlug={match && match.userSlug} />
        ) : (
          <Redirect to="/tracks" />
        )
      }
    </Match>
  );
};

export default UserPages;
