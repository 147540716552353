import React from 'react';

class Location extends React.Component {
  static defaultProps = {
    width: '25px',
    height: '25px',
    color: '#31AB6B',
  };

  render() {
    return (
      <svg
        version="1.1"
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 15.3 22.3">
        <path
          fill={this.props.color}
          d="M7.7,0C3.4,0,0,3.4,0,7.7s7.7,14.7,7.7,14.7s7.7-10.4,7.7-14.7S11.9,0,7.7,0z M7.7,10.7c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2S9.4,10.7,7.7,10.7z"
        />
      </svg>
    );
  }
}

export default Location;
