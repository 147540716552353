import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({color, width = '26px', height = '34px'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.35 34.26"
      height={height}
      width={width}>
      <path fill="#667078" d="M13.17 21.38H8.23v12.88l4.94-3.53zm0 0" />
      <path fill="#3f454a" d="M13.17 21.38h4.94v12.88l-4.9-3.53zm0 0" />
      <path
        fill="#2ea868"
        d="M11.88.62c.66-.82 1.92-.83 2.6 0 1.12 1.4 1.5 1.46 3.1.7.96-.45 2.1.1 2.34 1.13.4 1.72.7 1.97 2.5 2 1.06 0 1.84 1 1.6 2.02-.37 1.73-.2 2.08 1.4 2.87.95.47 1.22 1.7.57 2.53-1.1 1.4-1.1 1.8 0 3.2.6.83.3 2.05-.6 2.52-1.6.7-1.8 1.1-1.4 2.8.2 1-.6 2-1.6 2-1.4 0-2-.1-2.5 2-.3 1-1.4 1.6-2.4 1.1-1.3-.6-1.78-.95-3.1.7-.7.83-1.95.83-2.6 0-1.15-1.4-1.5-1.45-3.1-.7-.98.45-2.1-.1-2.36-1.13-.4-1.7-.7-1.96-2.5-2-1.06 0-1.84-.97-1.6-2 .36-1.73.2-2.1-1.4-2.9C-.1 17.04-.36 15.82.3 15c1.1-1.4 1.1-1.8 0-3.2-.6-.8-.35-2 .6-2.5 1.6-.77 1.8-1.1 1.4-2.87-.24-1.03.54-2.02 1.6-2.03 1.8 0 2.1-.3 2.5-2 .3-1 1.4-1.5 2.35-1.05 1.6.73 1.98.67 3.12-.72"
      />
      <path
        fill="#1e975e"
        d="M22.22 13.47c0 5-4.05 9.05-9.05 9.05-5 0-9.05-4.05-9.05-9.05 0-5 4.05-9.05 9.05-9.05 5 0 9.05 4.05 9.05 9.05"
      />
      <path
        fill="#fff"
        d="M11.97 16.3L9.6 13.95l.7-.7 1.67 1.67 4.08-4.08.7.7zm0 0"
      />
    </svg>
  );
};

Badge.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Badge;
