/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowDown from 'modules/common/Svg/ArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {compose, withHandlers, withState} from 'recompose';
import bgCertificate from 'theme/assets/images/bg-certificate.svg';
import medalCertificate from 'theme/assets/images/medal-certificate.svg';
import {achCountColor, bgLightBlue, marginIcon} from './styles.scss';

const ProfileCertificates = ({
  certificates,
  isCurrentUser,
  showMore,
  visibleBoxes,
}) => {
  const certificatesArray = Object.values(certificates.data).filter(
    (certificate) => typeof certificate === 'object',
  );
  return (
    <div className="bg-white pt3 ph3">
      <div className="flex">
        <h2 className="f3 fw6 mt4 mr3" data-cy="certificates-title">
          <FormattedMessage id="certificates" />
        </h2>
        <p className={`f4 fw6 ${achCountColor} mt4 mr2`}>
          ({certificatesArray.length})
        </p>
      </div>
      {certificatesArray.length > 0 ? (
        <div>
          {certificatesArray.map(
            ({track_name, created_at}, i) =>
              i < visibleBoxes && (
                <div
                  key={i}
                  className="fr w-40-ns w-100 w-50-m w-50-mid pt4 b--light-gray ba mh3-ns br2 cover mv3"
                  style={{backgroundImage: `url('${bgCertificate}')`}}
                >
                  <div className="ph3">
                    <h3 className="white pb3 mt2">
                      <FormattedMessage id="track_certificate" />
                    </h3>
                    <h5 className="pv3 f5 white">{track_name}</h5>
                    <p className="f6 mt3 near-white pb4">
                      <FormattedDate
                        value={new Date(created_at)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    </p>
                  </div>
                  <div className={`${bgLightBlue} pv3 bottom-0 tc`}>
                    <img alt="medal certificate" src={medalCertificate} />
                  </div>
                </div>
              ),
          )}
          {visibleBoxes < certificatesArray.length && (
            <div className="cr tc">
              <a
                className="pointer pa2 dib f6 f5-ns fw6 tc no-underline ba bw1 br2 b--light-gray gray"
                onClick={showMore}
              >
                <div className="flex justify-between ph2">
                  <p className="ph1">
                    <FormattedMessage id="more" />
                  </p>
                  <div className={`${marginIcon} ph1`}>
                    <ArrowDown color="gray" />
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="pa4 f4 tc">
            <FormattedMessage
              id={isCurrentUser ? 'no_certificates_self' : 'no_certificates'}
            />
          </p>
        </div>
      )}
    </div>
  );
};

ProfileCertificates.propTypes = {
  certificates: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  showMore: PropTypes.func.isRequired,
  visibleBoxes: PropTypes.number,
};

const enhance = compose(
  withState('visibleBoxes', 'setVisibleBoxes', 2),
  withHandlers({
    showMore: ({setVisibleBoxes}) => () => {
      setVisibleBoxes((visibleBoxes) => visibleBoxes + 2);
    },
  }),
);

export default enhance(ProfileCertificates);
