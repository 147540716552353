import notification from 'core/libs/helpers/notification';
import {redirectIf, spinnerWhileLoading} from 'core/libs/hoc';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle, setPropTypes, withPropsOnChange} from 'recompose';
import {getAchievements, getCertificates, getUser} from './actions';
import {
  ProfileAchievements,
  ProfileCertificates,
  ProfileInformations,
} from './components';

const {PARTNER} = process.env;

const ProfileContainer = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    achievements,
    authenticated,
    certificates,
    isCurrentUser,
    profile,
  } = props;
  return (
    <>
      <Head
        title={`${profile.first_name} ${profile.last_name} | ${formatMessage({
          id: PARTNER === 'BARMEJ' ? 'helmet_barmej' : 'partner_helmet',
        })}`}
      />
      <div className="w-100 bg-darker-primary-blue tr-ns tc">
        <div className="w-80-ns w-90 center pv5 tr-ns tc white" />
      </div>
      <div className="flex flex-row-ns flex-column ph4-ns pb5">
        <ProfileInformations
          authenticated={authenticated}
          profile={profile}
          isCurrentUser={isCurrentUser}
          userScore={profile.total_score}
        />
        <div className="w-100 flex flex-column">
          <ProfileAchievements
            achievements={achievements}
            isCurrentUser={isCurrentUser}
            visibleBoxes={6}
          />
          {PARTNER === 'BARMEJ' && (
            <ProfileCertificates
              certificates={certificates}
              isCurrentUser={isCurrentUser}
              visibleBoxes={2}
            />
          )}
        </div>
      </div>
    </>
  );
};

ProfileContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  achievements: PropTypes.object.isRequired,
  certificates: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

const mapStateToProps = ({profiles, user, auth}) => ({
  achievementsUser: profiles.achievementsUser,
  authenticated: auth.authenticated,
  certificatesUser: profiles.certificatesUser,
  currentUserProfile: user.profile,
  profileUser: profiles.profileUser,
});

const mapDispatchToProps = {
  dispatchProfileUser: getUser,
  dispatchAchievementsUser: getAchievements,
  dispatchCertificatesUser: getCertificates,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    profileUser: PropTypes.object.isRequired,
    userSlug: PropTypes.string.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const {dispatchProfileUser, userSlug} = this.props;
      dispatchProfileUser(userSlug);
    },
  }),
  redirectIf(({profileUser}) => {
    if (profileUser.status === 'rejected') {
      notification({id: '404'});
      return true;
    }
    return false;
  }, '/404'),
  spinnerWhileLoading(
    ({profileUser, currentUserProfile}) =>
      profileUser.status !== 'fetched' &&
      currentUserProfile.status !== 'fetched',
  ),
  lifecycle({
    componentDidMount() {
      const {
        dispatchAchievementsUser,
        dispatchCertificatesUser,
        profileUser: {
          data: {id},
        },
      } = this.props;
      dispatchAchievementsUser(id);
      if (PARTNER === 'BARMEJ') dispatchCertificatesUser(id);
    },
  }),
  spinnerWhileLoading(
    ({achievementsUser, certificatesUser}) =>
      !achievementsUser && !certificatesUser,
  ),
  withPropsOnChange(
    [
      'achievementsUser',
      'certificatesUser',
      'currentUserProfile',
      'profileUser',
    ],
    ({
      achievementsUser,
      certificatesUser,
      currentUserProfile,
      profileUser,
    }) => ({
      achievements: achievementsUser,
      certificates: certificatesUser,
      profile: profileUser.data,
      isCurrentUser: currentUserProfile.data.id === profileUser.data.id,
    }),
  ),
);

export default enhance(ProfileContainer);
