export const getUser = (userSlug) => (dispatch, getState) => {
  const {auth, user} = getState();
  const {authenticated} = auth;
  const {profile} = user;
  dispatch({
    types: [
      'GET_USER_PROFILE_REQUEST',
      'GET_USER_PROFILE_SUCCESS',
      'GET_USER_PROFILE_FAILURE',
    ],
    config: {
      url:
        authenticated && profile.data.slug === userSlug
          ? `profile/profiles/${userSlug}/`
          : `profile/public-profiles/${userSlug}/`,
    },
  });
};

export const getAchievements = (id) => {
  return {
    types: [
      'GET_ACHIEVEMENTS_REQUEST',
      'GET_ACHIEVEMENTS_SUCCESS',
      'GET_ACHIEVEMENTS_FAILURE',
    ],
    config: {
      url: `gamify/user-achievements-list/${id}/`,
    },
  };
};

export const getCertificates = (id) => {
  return {
    types: [
      'GET_CERTIFICATES_REQUEST',
      'GET_CERTIFICATES_SUCCESS',
      'GET_CERTIFICATES_FAILURE',
    ],
    config: {
      url: `school/user-certificates-list/${id}/`,
    },
  };
};

export const getLatestPosts = (id) => {
  return {
    types: [
      'GET_LATEST_POSTS_REQUEST',
      'GET_LATEST_POSTS_SUCCESS',
      'GET_LATEST_POSTS_FAILURE',
    ],
    config: {
      url: `school/questions/?user_id=${id}`,
    },
  };
};

export default getUser;
